import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw } from "vue-router";
import store from "@/store/index";

const routerGuard = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.meta.protectedRoute && store.getters.isAuthenticated) {
    next();
  } else if (to.meta.protectedRoute && !store.getters.isAuthenticated) {
    next({ name: "HomeIndexVue" });
  } else if (!to.meta.protectedRoute && store.getters.isAuthenticated) {
    next({ name: "AppCrmCustomerManagementIndexVue" });
  } else {
    next();
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "HomeIndexVue",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "home-index" */ "../views/home/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/login",
    name: "AuthenticationLoginIndexVue",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "authentication-login-index" */ "../views/authentication/login/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/password-recovery",
    name: "AuthenticationPasswordRecoveryIndexVue",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "authentication-password-recovery-index" */ "../views/authentication/password-recovery/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/authentication/password-recovery-verification/:token",
    name: "AuthenticationPasswordRecoveryVerificationIndexVue",
    meta: {
      protectedRoute: false,
    },
    component: () => import(/* webpackChunkName: "authentication-password-recovery-verification-index" */ "../views/authentication/password-recovery-verification/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/users",
    name: "AppAdministrationUsersIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-users-index" */ "../views/app/administration/users/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/administration/roles",
    name: "AppAdministrationRolesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-administration-roles-index" */ "../views/app/administration/roles/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/crm/customer-management",
    name: "AppCrmCustomerManagementIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-crm-customer-management-index" */ "../views/app/crm/customer-management/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/crm/customer-management/:id/general",
    name: "AppCrmCustomerManagementDetailGeneralIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-crm-customer-management-detail-general-index" */ "../views/app/crm/customer-management/detail/general/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/crm/customer-management/:id/contract",
    name: "AppCrmCustomerManagementDetailContractIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-crm-customer-management-detail-contract-index" */ "../views/app/crm/customer-management/detail/contract/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/crm/customer-management/:id/devices",
    name: "AppCrmCustomerManagementDetailDevicesIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-crm-customer-management-detail-devices-index" */ "../views/app/crm/customer-management/detail/devices/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/crm/customer-management/:id/payments",
    name: "AppCrmCustomerManagementDetailPaymentsIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-crm-customer-management-detail-payments-index" */ "../views/app/crm/customer-management/detail/payments/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/crm/customer-management/:id/payments/:paymentId",
    name: "AppCrmCustomerManagementDetailPaymentsDetailIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-crm-customer-management-detail-payments-detail-index" */ "../views/app/crm/customer-management/detail/payments/detail/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/crm/customer-management/:id/users",
    name: "AppCrmCustomerManagementDetailUsersIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-crm-customer-management-detail-users-index" */ "../views/app/crm/customer-management/detail/users/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/crm/referral-management",
    name: "AppCrmReferralManagementIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-crm-referral-management-index" */ "../views/app/crm/referral-management/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/crm/referral-management/:id/general",
    name: "AppCrmReferralManagementDetailGeneralIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-crm-referral-management-detail-general-index" */ "../views/app/crm/referral-management/detail/general/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/crm/referral-management/:id/referred-customers",
    name: "AppCrmReferralManagementDetailReferredCustomersIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-crm-referral-management-detail-referred-customers-index" */ "../views/app/crm/referral-management/detail/referred-customers/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/device-inventory/device-management",
    name: "AppDeviceInventoryDeviceManagementIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-device-inventory-device-management-index" */ "../views/app/device-inventory/device-management/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/device-inventory/device-management/:id/general",
    name: "AppDeviceInventoryDeviceManagementDetailGeneralIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-device-inventory-device-management-detail-general-index" */ "../views/app/device-inventory/device-management/detail/general/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/device-inventory/device-management/:id/maintenance-log",
    name: "AppDeviceInventoryDeviceManagementDetailMaintenanceLogIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-device-inventory-device-management-detail-maintenance-log-index" */ "../views/app/device-inventory/device-management/detail/maintenance-log/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/device-inventory/device-management/:id/manage-device",
    name: "AppDeviceInventoryDeviceManagementDetailManageDeviceIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-device-inventory-device-management-detail-manage-device-index" */ "../views/app/device-inventory/device-management/detail/manage-device/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/device-inventory/facility-management",
    name: "AppDeviceInventoryFacilityManagementIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-device-inventory-facility-management-index" */ "../views/app/device-inventory/facility-management/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/device-inventory/facility-management/:id/detail",
    name: "AppDeviceInventoryFacilityManagementDetailIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-device-inventory-facility-management-detail-index" */ "../views/app/device-inventory/facility-management/detail/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/device-inventory/inventory-overview",
    name: "AppDeviceInventoryInventoryOverviewIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-device-inventory-inventory-overview-index" */ "../views/app/device-inventory/inventory-overview/index.vue"),
    beforeEnter: routerGuard,
  },
  {
    path: "/app/reset-password",
    name: "AppResetPasswordIndexVue",
    meta: {
      protectedRoute: true,
    },
    component: () => import(/* webpackChunkName: "app-reset-password-index" */ "../views/app/reset-password/index.vue"),
    beforeEnter: routerGuard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

export default router;
